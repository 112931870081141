import clsx from 'clsx'
import type { ComponentPropsWithoutRef } from 'react'

export function Loading({
  isLoading = false,
  invert = false,
  isBlock = false,
  className,
}: {
  isLoading?: boolean
  invert?: boolean
  isBlock?: boolean
} & ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      className={clsx(
        'mt-1 h-4 w-4 animate-spin rounded-full border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
        isLoading && invert
          ? 'border-2 border-solid border-transparent-600'
          : '',
        isBlock ? 'block' : 'inline-block',
        isLoading && !invert ? 'border-2 border-solid border-blue-600' : '',
        !isLoading ? 'border-0' : '',
        className,
      )}
      role="status"
    />
  )
}
